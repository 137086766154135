import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showHamburger: false,
    showWorkModal: false
  },
  mutations: {
    toggleShowHamburger(state) {
      state.showHamburger = !state.showHamburger;
    },
    toggleShowWorkModal(state) {
      state.showWorkModal = !state.showWorkModal;
    }
  },
  getters: {
    getShowHamburger: state => state.showHamburger,
    getShowWorkModal: state => state.showWorkModal
  },
  actions: {},
  modules: {}
});
