<template>
  <div class="home">
    <div class="logo">
      <img :src="LogoImage" alt="Abudriman's personal logo" />
    </div>
    <div v-if="home.length" class="welcome-text">
      <div>
        <h4>HI THERE !</h4>
        <h1>I'M <span>ARIF BUDIMAN</span></h1>
        <h4>
          {{ home[0].text }}
        </h4>
        <a
          :href="funlink"
          target="_blank"
          rel="noreferrer noopener"
          @click="randomFunlink"
          >Go Somewhere Fun</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import LogoImage from "../assets/logo-ab.png";
import { db } from "../firestore";
const homeCollection = db.collection("home");

export default {
  name: "Home",
  components: {},
  data: function() {
    return {
      LogoImage: LogoImage,
      home: [],
      funlink: "https://www.google.com"
    };
  },
  mounted() {
    this.$bind("home", homeCollection).then(() => {
      this.randomFunlink();
    });
  },
  methods: {
    randomFunlink: function() {
      let random = Math.floor(Math.random() * this.home[0].funlink.length);
      this.funlink = this.home[0].funlink[random];
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  flex-grow: 1;
  display: flex;
  height: 87%;
}
.logo {
  grid-area: logo;
  display: none;
}
.welcome-text {
  grid-area: text;
  display: flex;
  align-items: center;
  div {
    padding: 0 40px;
  }
  h4 {
    font-weight: 400;
    padding: 0;
  }
  h4:nth-child(1) {
    margin: 0;
  }
  h4:nth-child(3) {
    margin: 0 0 30px 0;
  }
  h1 {
    padding: 10px 0;
    margin: 0;
  }
  span {
    color: var(--color07);
  }
  a {
    background-color: var(--color00);
    color: var(--dark00);
    border-radius: 20px;
    box-shadow: none;
    outline: none;
    border: none;
    padding: 7px;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    text-decoration: none;
  }
  a:hover {
    background-color: var(--color07);
    color: var(--light00);
  }
}

@media (min-width: 750px) {
  .home {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "logo text";
  }
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
