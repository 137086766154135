import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "normalize.css";
import VueFeather from "vue-feather";
import VueLazyload from "vue-lazyload";
import UUID from "vue-uuid";
import { firestorePlugin } from "vuefire";

Vue.use(UUID);
Vue.use(VueFeather);
Vue.use(VueLazyload);
Vue.use(firestorePlugin);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
