<template>
  <div class="mobile-navbar">
    <div class="grid-container">
      <div
        :class="this.$route.name.toLowerCase() == 'works' ? 'active' : ''"
        @click="navigateTo('works')"
      >
        Works
      </div>
      <div
        :class="this.$route.name.toLowerCase() == 'home' ? 'active' : ''"
        @click="navigateTo('')"
      >
        <feather type="home"></feather>
      </div>
      <div
        :class="this.$route.name.toLowerCase() == 'contact' ? 'active' : ''"
        @click="navigateTo('contact')"
      >
        Hire Me
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileNavbar",
  methods: {
    navigateTo(page) {
      let path = "/" + page;
      if (this.$route.path == path) {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      } else {
        this.$router.push({ path: path });
      }
    }
  }
};
</script>

<style lang="scss">
.mobile-navbar {
  background-color: var(--dark02);
  width: 100%;
  color: var(--light00);
  font-family: "Montserrat", sans-serif;
}

.grid-container {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". . .";
  max-height: 100px;
  min-height: 50px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.active {
  color: var(--color00);
}

@media (min-width: 750px) {
  .mobile-navbar {
    display: none;
  }
}
</style>
