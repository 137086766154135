import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    component: () =>
      import(/* webpackChunkName: "error404" */ "../views/404.vue"),
    meta: {
      title: "Page Not Found"
    }
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Homepage"
    }
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      title: "About"
    }
  },
  {
    path: "/works",
    name: "Works",
    component: () =>
      import(/* webpackChunkName: "works" */ "../views/Works.vue"),
    meta: {
      title: "Works"
    }
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
    meta: {
      title: "Contact"
    }
  },
  {
    path: "/inspect",
    name: "Inspect",
    component: () =>
      import(/* webpackChunkName: "inspect" */ "../views/Inspect.vue"),
    meta: {
      title: "Inspect"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
