<template>
  <div class="navbar">
    <transition name="fade">
      <div v-if="this.$store.getters.getShowHamburger" class="hamburger-menu">
        <p>
          <feather type="x" @click="toggleHamburgerMenu()"></feather>
        </p>
        <ul>
          <li @click="toggleHamburgerMenu()">
            <router-link to="/" exact>HOME</router-link>
          </li>
          <li @click="toggleHamburgerMenu()">
            <router-link to="/about">ABOUT</router-link>
          </li>
          <li @click="toggleHamburgerMenu()">
            <router-link to="/works">WORKS</router-link>
          </li>
          <li @click="toggleHamburgerMenu()">
            <router-link to="/contact">CONTACT</router-link>
          </li>
          <li @click="toggleHamburgerMenu()">
            <router-link to="/inspect">INSPECT</router-link>
          </li>
        </ul>
      </div>
    </transition>

    <div class="hamburger-button">
      <feather type="align-justify" @click="toggleHamburgerMenu()"></feather>
    </div>
    <div class="links">
      <router-link to="/" exact>Home</router-link>
      <router-link to="/about">About</router-link>
      <router-link to="/works">Works</router-link>
      <router-link to="/contact">Contact</router-link>
      <router-link to="/inspect">Inspect</router-link>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    toggleHamburgerMenu() {
      this.$store.commit("toggleShowHamburger");
    }
  }
};
</script>

<style>
.navbar {
  display: grid;
  grid-template-rows: 50px;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}
.links {
  grid-row: 1/2;
  grid-column: 2/3;
  display: none;
  justify-content: space-evenly;
  font-family: "Montserrat", sans-serif;
}

.links a,
.links a:visited {
  color: var(--dark00);
  background-image: linear-gradient(var(--light00), var(--light00)),
    linear-gradient(var(--color00), var(--color00));
  text-decoration: none;
  background-size: 100% 3px, 100% 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat, no-repeat;
  transition: 0.2s;
}

body.dark-mode .links a,
body.dark-mode .links a:visited {
  color: var(--light00);
  background-image: linear-gradient(var(--dark00), var(--dark00)),
    linear-gradient(var(--color00), var(--color00));
}

.links a.router-link-active {
  background-image: linear-gradient(var(--color00), var(--color00)),
    linear-gradient(var(--color00), var(--color00));
  background-size: 100% 3px, 100% 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat, no-repeat;
}

body.dark-mode .links a.router-link-active {
  background-image: linear-gradient(var(--color00), var(--color00)),
    linear-gradient(var(--color00), var(--color00));
  background-size: 100% 3px, 100% 3px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat, no-repeat;
}

.links a:hover {
  background-size: 0 3px, 100% 3px;
}

.hamburger-button {
  grid-row: 1/2;
  grid-column: 2/3;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  display: flex;
  justify-content: flex-end;
}
.hamburger-button i {
  cursor: pointer;
  padding: 20px;
}

.hamburger-menu {
  position: fixed;
  top: 0;
  z-index: 1;
  background-color: #000000c7;
  color: white;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.hamburger-menu p {
  align-self: flex-end;
  padding: 20px;
  margin: 0;
  cursor: pointer;
}
.hamburger-menu ul {
  padding: 0;
  list-style: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.hamburger-menu li {
  padding: 10px 0px;
}

.hamburger-menu a,
.hamburger-menu a:visited {
  color: var(--light00);
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
}

.hamburger-menu a:hover {
  border-bottom: 1px solid var(--light00);
}

.hamburger-menu a.router-link-active {
  color: var(--color00);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@media (min-width: 750px) {
  .navbar {
    box-shadow: none;
  }
  .links {
    display: flex;
  }
  .hamburger-button {
    display: none;
  }
}
</style>
