<template>
  <div id="app">
    <Navbar />
    <router-view />
    <MobileNavbar />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import MobileNavbar from "../src/components/MobileNavbar";

export default {
  components: {
    Navbar,
    MobileNavbar
  },
  watch: {
    /*eslint-disable-next-line */
    $route(to, from) {
      document.title =
        to.meta.title + " | Abudriman's Online Journal" ||
        "Abudriman's Online Journal";
    }
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
:root {
  --dark00: #121212;
  --dark01: #272727;
  --dark02: #2c2c2c;
  --dark03: #333333;
  --dark04: #383838;
  --light00: #f4f6f9;
  --light01: #f6f6f6;
  --light02: #ececec;
  --light03: #e1e1e1;
  --light04: #d0d0d0;
  --color00: #fec257;
  --color01: #a6b855;
  --color02: #59a26a;
  --color03: #198678;
  --color04: #176773;
  --color05: #2f4858;
  --color06: #8083ff;
  --color07: #5c4aaf;
  --error: #c1554e;
}
html,
body,
#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
body {
  background-color: var(--light00);
  color: var(--dark00);
}

body.dark-mode {
  background-color: var(--dark00);
  color: var(--light00);
}

#app {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
}

strong {
  color: var(--color06);
}

/* spinner color */
.load3:before {
  background: #fec257 !important;
}
.load3:after {
  background: #fec257 !important;
}
.load3-center {
  background: var(--color07) !important;
}

@media (max-width: 749px) {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .about::-webkit-scrollbar,
  .works::-webkit-scrollbar,
  .contact::-webkit-scrollbar,
  .inspect::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .about,
  .works,
  .contact,
  .inspect {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@media (max-width: 1920px) {
  body p.satu {
    font-size: 13px;
  }
  body p.dua,
  body a {
    font-size: 16px;
  }
  body p.tiga {
    font-size: 18px;
  }
  body h1 {
    font-size: 48px;
  }
  body h2 {
    font-size: 38px;
  }
  body h3 {
    font-size: 28px;
  }
  body h4 {
    font-size: 18px;
  }
}

@media (min-width: 1921px) {
  body p.satu {
    font-size: 16px;
  }
  body p.dua,
  body a {
    font-size: 19px;
  }
  body p.tiga {
    font-size: 21px;
  }
  body h1 {
    font-size: 58px;
  }
  body h2 {
    font-size: 48px;
  }
  body h3 {
    font-size: 38px;
  }
  body h4 {
    font-size: 28px;
  }
}
</style>
