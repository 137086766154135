// Get a RTDB instance
import firebase from "firebase/app";
import "firebase/firestore";

const { Timestamp } = firebase.firestore;

const db = firebase
  .initializeApp({
    apiKey: "AIzaSyDMAMbGgjyObDOKpBKwZz1CE0p0P3t5jIk",
    authDomain: "journal-abud-top.firebaseapp.com",
    projectId: "journal-abud-top",
    storageBucket: "journal-abud-top.appspot.com",
    messagingSenderId: "91315955712",
    appId: "1:91315955712:web:bf458e2460221faf91d0e5",
    measurementId: "G-R0MZ7TTL26"
  })
  .firestore();

export { Timestamp, db };
